import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import brand from "../../../assets/images/logo-icon-blk.png";
import ArrowIcon from "../../../assets/images/right-arrow.png";
import PaymentInputs from "../../../components/stripePaymentCustomForm/inputCardValidate";
import { useSelector } from "react-redux";
import { stripePaymentMethodHandler } from "../../../utils/paymentConfirmation";
import { CardElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { toast } from "react-toastify";
import {
  API_PAYMENT_INTENT_SECRET,
  API_PAYMENT_REMOVE_DUPLICATE,
  API_PAYMENT_SAVE_PAYMENT,
  API_PERSONAL_BUY_MULTIPLE_PROFILE,
  API_JOB_SEEKER_UPGRADE_PROFILE,
  postApi,
} from "../../../utils";
import GoBack from "../../../components/GoBack";

function AddCard(props) {
  console.log(props, "props");
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userId = useSelector(
    (state) => state.UserAuth.userData.payload.user._id
  );

  const planId = props?.planName;
  const credit = props?.credit;
  const payAsYouGoProfileIds = props?.payAsYouGoProfileIds;

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [name, setName] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [isRemember, setRememberCard] = useState(false);
  const [client_secret, setclient_secret] = useState();
  // const [payAsyouGoAmount, setpayAsyouGoAmount] = useState(0);
  const userType = useSelector(state => state.userType.userType);

  var amount = props?.amount;


  // if (userType.payload === 'jobSeeker') {
  //   amount = 35;
  //   console.log("this is job seeker so changing the amount to 35")
  // } else {
  console.log("this is props data to show plan ID", planId);
  console.log("--------this is pay as you go profile ids", payAsYouGoProfileIds, "pay as you amount", props?.payAsyougoAmount);

  if (planId === undefined) {
    console.log("---------thisss is pay as you go purchasing --------", props?.payAsyougoAmount, "----credit", payAsYouGoProfileIds);
    amount = props?.payAsyougoAmount
  }
  // }

  const currency = "INR";
  const paymentMode = "card";
  // create payment,intedent call backend
  const bodyIntentSecret = {
    amount,
    currency,
    payment_method_types: [paymentMode],
    isRemember,
  };

  useEffect(() => {
    setErrorMsg("");

    postApi(
      API_PAYMENT_INTENT_SECRET,
      bodyIntentSecret,
      userToken,
      ({ data: { client_secret } }) => {
        setclient_secret(client_secret);
      },
      errorFunction
    );
  }, [stripe, elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    setLoading(true);

    if (!client_secret) {
      setLoading(false);
      return;
    }

    //create payment method
    const cardElement = elements.getElement(CardNumberElement);
    const { paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    //confirm payment
    const { error: stripeError, paymentIntent } = await stripe
      .confirmCardPayment(client_secret, {
        payment_method: paymentMethod.id,
      })
      .catch((e) => {
        console.log(e);
      });

    if (stripeError) {
      // Show error to your customer (e.g., insufficient funds)
      toast.error(stripeError.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setLoading(false);
      return;
    }
    //remove duplicate saved card in stripe
    if (isRemember) {
      postApi(
        API_PAYMENT_REMOVE_DUPLICATE,
        {},
        userToken,
        successFunction,
        errorFunction
      );
    }
    if (userType.payload === 'jobSeeker') {
      postApi(
        API_JOB_SEEKER_UPGRADE_PROFILE,
        {
          userId: userId
        },
        userToken,
        sucessFunctionPur,
        errorFunction
      );
    } else {
      console.log(" -- trigerring this cause its poster --- ")
      if (planId === undefined) {
        // this is pay as you payment
        console.log(" -- trigerring this buy multiple profiles for pay-as-you-go --- ")
        const obj = {
          pointInfoId: payAsYouGoProfileIds,
          credit: payAsYouGoProfileIds?.length,
          payAsyouGo: true,
        };
        postApi(
          API_PERSONAL_BUY_MULTIPLE_PROFILE,
          obj,
          userToken,
          sucessFunctionPur,
          errorFunction
        );
      } else {
        //save payment details to databse
        console.log(" -- trigerring this save payment --- ")
        const dataPaymentBody = {
          name,
          transactionId: paymentIntent.id,
          userId,
          planId,
          amount,
          credit,
          usertype: 2,
          currency,
          paymentMode,
          status: paymentIntent.status,
          cardNo: paymentMethod.card.last4,
        };

        postApi(
          API_PAYMENT_SAVE_PAYMENT,
          dataPaymentBody,
          userToken,
          successFunction,
          errorFunction
        );
      }
    }

    toast.success(
      `Payment of ${bodyIntentSecret.amount} ${paymentIntent.status}`,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    props.setPaymentIntent(paymentIntent);
    setLoading(false);
    props.setPaymentCompleted(true);
  };

  const successFunction = ({ data }) => {
    console.log(data);
  };
  const errorFunction = (err) => {
    console.log(err, "sorry error ocured");
    setLoading(false);
  };
  const sucessFunctionPur = (res) => {
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        lineHeight: "27px",
        color: "#212529",
        fontSize: "15px",

        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  //
  return client_secret ? (
    <section id="form-page" className="red-graphics-top">
      <div className="container">
        <GoBack />
        <div className="row">
          <div className="col-lg-4 col-md-3 col-sm-2" />
          <div className="col-lg-4 col-md-6 col-sm-8 text-center">
            <div className="brand-logo">
              <img src={brand} alt="brand" />
              <br />
              <br />
              {/* style="color:white; font-weight: bold;" */}
              <h3>Add new card</h3>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="row">
                {/* <PaymentInputs onTouch={getCardData} /> */}

                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="signup-input-bx ">
                      <label htmlFor="cc-name">Name on card</label>
                      <input
                        id="cc-name"
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="signup-input-bx ">
                      <label htmlFor="cc-number">Card Number</label>
                      <CardNumberElement
                        id="cc-number"
                        className="form-control div-style"
                        options={CARD_ELEMENT_OPTIONS}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="signup-input-bx ">
                      <label htmlFor="expiry">Expiration Date</label>
                      <CardExpiryElement
                        id="expiry"
                        className="form-control"
                        options={CARD_ELEMENT_OPTIONS}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="signup-input-bx ">
                      <label htmlFor="cvc">CVC</label>
                      <CardCvcElement
                        id="cvc"
                        className="form-control"
                        options={CARD_ELEMENT_OPTIONS}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-sm-12 mt-2 add_card-checkbox">
                    <button
                      className="btn btn-dark w-100"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                        />
                      ) : (
                        `PAY £ ${amount}`
                      )}
                    </button>
                  </div>
                </div>
                {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
                <div className="row">
                  <div className="col-lg-12 col-sm-12 mt-4 ps-3 mx-3 add_card-checkbox">
                    <label className="switch">
                      Remember Card
                      <input
                        type="checkbox"
                        onChange={() => setRememberCard(!isRemember)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </form>

            <br />
            <br />
            <br />
          </div>
          <div className="col-lg-4 col-md-3 col-sm-2" />
        </div>
      </div>
    </section>
  ) : (
    <div className="d-flex justify-content-center align-items-center LoaderCenter">
      {" "}
      <div className="block">
        <span
          className="spinner-grow spinner-grow-lg mx-2"
          role="status"
          aria-hidden="true"
        />
        <h6 className="LoaderText">Processing...</h6>
      </div>
    </div>
  );
}

export default AddCard;
