import {useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import React, {useEffect, useState} from 'react';
import {API_GET_POSTER_COMPANIES, postApiPoster} from '../../../../utils';
import EditButton from "../../../../assets/images/edit-icon.png";
import RightArrow from "../../../../assets/images/right-arrow.png";
import logo from "../../../../assets/images/right-arrow.png";

export default function CompaniesPage() {
const navigate = useNavigate();
const location = useLocation();

const [companyList, setCompanyList] = useState([]);
const [loading, setLoading] = useState(true);

const userToken = useSelector(state => state.UserAuth.userData.payload.token);
const userId = useSelector(state => state.UserAuth.userData.payload.user._id);

const requestDataFromServer = () => {
	const obj = {
		userId: userId,
	};
	postApiPoster(API_GET_POSTER_COMPANIES, obj, userToken, successFunction, errorFunction);
};
const successFunction = res => {
	console.log(res, 'res');
	setLoading(false);
	if (res.status === 200) {
		setCompanyList(res.data.result);
	}
	setCompanyList(res.data.result);
};
const errorFunction = errr => {
	toast.error('Oops! something went wrong!', {
		position: 'top-right',
		autoClose: 1000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};

useEffect(() => {
	requestDataFromServer();
}, []);

const goToAddOrEditCompany = () => {
	if (location.pathname.indexOf("/companies") !== -1) {
		navigate('../addCompany')
	} else {
		navigate('./addCompany')
	}
};

const companyClicked = (company) => {
	navigate((location.pathname.indexOf("companies") !== -1 ? '..' : '.') + '/company/' + company._id, {state: company});
};

const editCompanyClicked = (company) => {
	if (location.pathname.indexOf("/companies") !== -1) {
		navigate('../editCompany/' + company._id, {state: company})
	} else {
		navigate('./editCompany/' + company._id, {state: company})
	}
};

return (
	<React.Fragment>
		{loading ? (
			<div className="d-flex justify-content-center align-items-center LoaderCenter">
				{' '}
				<div className="block">
					<span className="spinner-grow spinner-grow-lg mx-2" role="status" aria-hidden="true"/>
					<h6 className="LoaderText">Processing...</h6>
				</div>
			</div>
		) : (
			<div className="container">
				<div className="row dashboard-cnt-row d-flex align-items-center justify-content-center">

					<div className="col-lg-9 col-sm-8 col-xs-12 mircroshoft-cnt ">
						<div className="row ">
							<div className="col-lg-12 recent-page-heading-jp mb-4">
								<br/>
								<div className="perspectv-header" style={{background: '#ffdb59'}}>Your Companies</div>
							</div>
						</div>

						<div className="row mt-5 pb-5">
							{companyList?.length > 0 &&
								companyList?.map(e => (
									<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pb-2">
										<div className="current-job-col">
											<div className="col-12 d-flex justify-content-between">
												<img
													className=""
													style={{height: "20px"}}
													src={EditButton}
													onClick={() => editCompanyClicked(e)}
												/>
												<div className="col-10" onClick={() => {
													companyClicked(e)
												}}>
													<h5>{e.name}</h5>
													<p className={'capitalize'}>
														{e.location ?? ''}
													</p>
												</div>

												<img
													className=""
													style={{height: "20px"}}
													src={logo}
													onClick={() => {
														companyClicked(e)
													}}/>
											</div>
										</div>
									</div>
								))}
							{companyList?.length === 0 ? (
								<div><p>You currently have no companies added to your profile.</p><p>To add a company click on the add button below.</p></div>
							) : null}
						</div>
						<div className="row">
							<div className="col-lg-12 mt-5 text-center mb-5">
								<div className="perspectv-button" onClick={goToAddOrEditCompany}>
									<i className="fa fa-plus" style={{fontSize: '1.5rem'}}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
		}
	</React.Fragment>
)}
