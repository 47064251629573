import React, { useEffect, useState } from "react";
import Blk from "../../../../assets/images/logo-icon-blk.png";
import ArrowIcon from "../../../../assets/images/right-arrow.png";
import { API_GET_SUBSCRIPTION_LIST, getApi } from "../../../../utils";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import GoBack from "../../../../components/GoBack";
import { toast } from 'react-toastify';

export default function PaymentPlanScreen() {
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );

  const [planList, setPlanList] = useState([]);
  const [active, setActivePlan] = useState([]);
  const [getPlanType, setGetPlanType] = useState([]);
  const navigation = useNavigate();
  const { state } = useLocation();
  const planType = getPlanType ? getPlanType : 0;

  useEffect(() => {
    getPlanList();
    setGetPlanType(state?.planType);
  }, []);

  const getPlanList = () => {
    let axiosConfig = {
      headers: {
        type: "front-end",
        mode: 1,
        Authorization: "Bearer " + userToken,
      },
    };
    getApi(API_GET_SUBSCRIPTION_LIST, axiosConfig, onSuccess, onFailure);
  };

  const onSuccess = (res) => {
    const result = res.data.result;
    var plans = [];
    for (var i = 0; i < result.length; i++) {
      if (result[i].credit >= 0) {
        plans.push(result[i]);
      }
    }
    setPlanList([...plans]);
  };

  const onFailure = (err) => {
    console.log(err, "sorry error ocurred");
  };

  const nextPage = () => {
    navigation("../jobPoster/companies");
  };

  // Redirecting user to payment page
  const goToPaymentPage = () => {

    const choosenPlan = planList.filter((info) => info._id.includes(active));
    console.log("-------choosen plan----", active)
    if (active.length > 0) {
      if (choosenPlan[0].credit === 0) {
        nextPage();
      } else {
        navigation("../paymentMethod", { state: { planInfo: choosenPlan[0] } });
      }
    } else {
      console.log("please choose a plan")
      toast.error('please choose a plan', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  };

  return (
    <section
      id="feel-suite-best"
      style={{ backgroundPosition: "top :-66px center" }}
    >
      <div className="container">
        <GoBack />
        <div className="row">
          <div className="col-lg-12 text-center">
            <img src={Blk} />
            <h3>Choose a payment plan that suits you</h3>
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-12 text-center w-100 payment-plan">
            <p className="w-50 mx-auto">
              We offer {planType === 1 ? 2 : 3} flexible, affordable payment
              plans. Pick whichever one suits you best.
            </p>
          </div>
        </div>
        <div className="row mt-5 ">
          {planList &&
            planList.map((data, i) => {
              return planType === 1 && data.credit !== null ? (
                <>
                  <div
                    key={i}
                    className="col-lg-4 col-md-4 col-sm-12 col-12 text-center mb-4"
                  >
                    <div
                      key={i + 1}
                      className={
                        active.includes(data._id)
                          ? "payment-plan-col payment-plan-col-active "
                          : "payment-plan-col"
                      }
                      onClick={() => setActivePlan(data._id)}
                    >
                      <h5 key={i + 3}>{data.title}</h5>
                      <p key={i + 6}>{data.description}</p>
                      <br />
                      <br />
                      <br />

                      <h5 key={i + 66}>£{data.offerprice}</h5>
                      {data.credit ? (
                        <>
                          <span key={i + 4}>{`Credits ${data.credit ? data.credit : ""
                            }`}</span>
                          <br />
                          <span key={i + 7}>per profile</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              ) : planType === 0 ? (
                <>
                  <div
                    key={i}
                    className="col-lg-4 col-md-4 col-sm-12 col-12 text-center mb-4"
                  >
                    <div
                      key={i + 1}
                      className={
                        active.includes(data._id)
                          ? "payment-plan-col payment-plan-col-active "
                          : "payment-plan-col"
                      }
                      onClick={() => setActivePlan(data._id)}
                    >
                      <h5 key={i + 3}>{data.title}</h5>
                      <p key={i + 66}>{data.description}</p>
                      <br />
                      <br />
                      <br />

                      <h5 key={i + 6}>£{data.offerprice}</h5>
                      {data.credit ? (
                        <>
                          <span key={i + 4}>{`Credits ${data.credit ? data.credit : ""
                            }`}</span>
                          <br />
                          <span key={i + 7}>per profile</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              );
            })}
        </div>

        <div className="row">
          <div className="col-lg-12 text-center">
            <a  onClick={goToPaymentPage} className="payment-icon">
              <img src={ArrowIcon} />
            </a>
            <p>
              <a
                
                onClick={() => nextPage()}
                style={{
                  fontSize: "17px",
                  textDecoration: "none",
                  color: "#262626",
                }}
              >
                Skip
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
